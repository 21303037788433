<template>
  <div>
    <Cycles
      id="cycle"
      :first="locale[lang].PUISSANCE_J.FIRST"
      :images="images"
      :check="locale[lang].PUISSANCE_J.CHECK"
      :semaine="locale[lang].PUISSANCE_J.SEMAINE"
      :semaineImg="semaineImg"
      :apres="locale[lang].PUISSANCE_J.APRES"
      :exercices="locale[lang].PUISSANCE_J.EXERCICES"
      :infoShop="locale[lang].PUISSANCE_J.INFOSHOP"
    />
  </div>
</template>

<script>
import Cycles from "@/components/Cycles.vue";
export default {
  components: {
    Cycles,
  },
  data() {
    return {
      images: [
        "Cycles/PuiJ grupo 187.png",
        "Cycles/PuiJ grupo 188.png",
        "Cycles/PuiJ grupo 189.png",
      ],
      semaineImg: "https://da32ev14kd4yl.cloudfront.net/versioned/preparationphysiquefootball/ENDUR3.png",
      check: {
        title: "Adaptés aux U15-U18",
        checks: [
          `Vous êtes parfois en manque d’idées pour apporter de la nouveauté à vos séances d'entraînements ?`,
          `Vous aimeriez avoir des exemples de cycles d'entraînements pour le travail de l'endurance puissance chez les U15-U18 ?`,
          `Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet) ?`,
          `Vous aimeriez connaître plus d’exercices pour travailler l'endurance chez les U15-U18 ?`,
          `Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques ?`,
        ],
      },
      semaine: `<p>
              Nous proposons un programme en ligne avec
              <span>2 cycles de 4 semaines d'entraînements de l'endurance puissance</span>
              et 200 idées d'exercices pour travailler l'endurance.  
            </p>
            <p>
              Ce guide s’adresse aux coachs d'équipes U15-U18 qui cherchent des exemples de cycles d'entraînements et des idées d’exercices pour travailler l'endurance puissance.
            </p>
            <p>
              <i class="fas fa-check-circle"></i> Vous avez une partie “explications” sur la coordination
            </p>
            <p>
              <i class="fas fa-check-circle"></i> 2 cycles de 4 semaines sur le travail de coordination (avec 2 séances par semaine soit 16 séances pour travailler la coordination )
            </p>
            <p>
              <i class="fas fa-check-circle"></i> Des idées d'exercices pour travailler la coordination sans matériel ou avec petit matériel (que tous les clubs peuvent avoir, et tous avec ballon)
            </p>`,
      apres: {
        col1: `<p>
              Vous allez retrouver 2 cycles (de 4 semaines) d'entraînements physiques pour travailler l'endurance puissance chez les U15-U18. Vous pouvez proposer par exemple le premier cycle dans la première partie de saison et le second cycle dans le seconde partie de saison. Pour progresser, il faut parfois (voir souvent) apporter de la variété, de la nouveauté, afin de surprendre les organismes (source de progrès) et d'éviter la monotonie (perte de motivation, surentraînement...)
            </p>`,
        col2: `<p>
              Pour cela, vous pouvez varier les intensités, les volumes, ... mais aussi les exercices!
            </p>
            <p>
              Nous vous proposons ainsi plus de 200 exemples d'exercices pour travailler l'endurance (avec et sans ballon)
            </p>`,
      },
      exercices: {
        title: `EXERCICES PHYSIQUES SOUS FORMAT 3D`,
        subtitle: `Voici des exemples d'exercices physiques que vous retrouverez dans le programme en ligne.`,
        exercicesL: [
          {
            img: "Idees/Left1.png",
            title: "JEUX RÉDUITS 1 VS 1",
            text: `
          <p><i class="fas fa-check"></i> Sous forme de mini-tournoi (montée-descente) avec évolution des jeux.</p>
          <p><i class="fas fa-check"></i> Les gagnants montent pour finir sur le terrain 1. Les perdants descendent, le dernier terrain étant le terrain 4 (ou 5 ou 6 en fonction du nombre de joueurs présents).</p>
          <p><i class="fas fa-check"></i> Terrain 4 : Conservation du ballon (le joueur qui conserve le plus longtemps le ballon a gagné). </p>
          <p><i class="fas fa-check"></i> Terrain 3 : stop ball dans la zone rouge. </p>
          <p><i class="fas fa-check"></i> Terrain 2 : Le joueur doit passer entre les plots adverses (rouges ou bleus). </p>
          <p><i class="fas fa-check"></i> Terrain 1 : marquer dans le petit but adverse. L'intensité doit être grande.</p>
          `,
          },
          {
            img: "Idees/Left2.png",
            title: "JEUX RÉDUITS 4 VS 1",
            text: `
          <p><i class="fas fa-check"></i> 5 carrés.</p>
          <p><i class="fas fa-check"></i> 4 contre 1 dans chaque carré.</p>
          <p><i class="fas fa-check"></i> Parmi les 12 joueurs jaunes qui doivent conserver le ballon, 4 joueurs (flèches blanches) jouent dans 2 carrés en même temps.</p>
          <p><i class="fas fa-check"></i> Ces 4 joueurs ne doivent jamais recevoir 2 ballons en même temps.</p>
          <p><i class="fas fa-check"></i> Vous pouvez varier la taille des carrés, donner des consignes particulières, proposer d'autres contraintes, ....</p>
          `,
          },
        ],
        exercicesR: [
          {
            img: "Cycles/FutPui right-1.png",
            title: "JEUX RÉDUITS 5 VS 2",
            text: `
          <p><i class="fas fa-check"></i> 3 zones. </p>
          <p><i class="fas fa-check"></i> L'équipe en supériorité numérique doit passer les 3 ''étapes'' pour marquer un point.</p>
          <p><i class="fas fa-check"></i> L'équipe en supériorité numérique doit faire 8 passes avant de pouvoir passer à la zone suivante.</p>
          <p><i class="fas fa-check"></i> Dans chaque zone, 2 nouveaux joueurs au pressing.</p>
          <p><i class="fas fa-check"></i> L'équipe en supériorité numérique a par exemple 2 minutes pour passer les 3 étapes et marquer un point.</p>
          <p><i class="fas fa-check"></i> A chaque perte de balle, elle recommence de la zone de départ.</p>
          `,
          },
          {
            img: "Cycles/Avec right-2.png",
            title: "JEUX RÉDUITS 4 VS 4",
            text: `
          <p><i class="fas fa-check"></i> 3 équipes.</p>
          <p><i class="fas fa-check"></i> Une équipe qui attaque (en rouge) et choisie l'équipe contre laquelle elle va attaquer (contre les blancs ou les jaunes).</p>
          <p><i class="fas fa-check"></i> Si elle marque, elle attaque contre l'autre équipe. Si elle perd le ballon, elle passe en défense et l'autre équipe passe en attaque.</p>
          `,
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
#cycle >>> #first p {
  font-weight: 800;
}

#cycle >>> #first p span {
  font-weight: 500;
}
</style>
